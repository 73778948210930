@media screen and (max-width: 478px) {
  #header {
    display: flex;
    flex-direction: column;
  }
  #header button {
    margin-top: 10px;
  }
}

@media screen and (max-width: 349px) {
  #card-area {
    width: 80vw;
  }
}
