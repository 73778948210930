@media screen and (max-width: 450px) {
    #card-header{
        display: flex;
        flex-direction: column;
    }

    #card-header button{
        margin-top: 10px;
    }
}
@media screen and (max-width: 409px){
    #card-box{
        width: 75vw;
    }
}