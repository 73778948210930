/* footerStyle.css */
.footer {
	position: fixed;
	bottom: 0;
	right: 0;
	background-color: #f8f9fa;
	padding: 5px;
	padding-right: 15px;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	color: #151515;
	font-weight: 600;
	z-index: 1;
  }

  