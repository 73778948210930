.dropdown{
    position: relative;
    display: inline-block;
    width: 100%;
}

.filter-adjustment{
    position: sticky;
    top: 0;
    z-index: 1; 
    background: lavenderblush;
}

.dropdown-content{
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0,0,0.2);
    padding: 12px;
    z-index: 2;
}

.css-tj5bde-Svg{
    margin-top: -4px;
}

.css-qbdosj-Input{
    margin: 0 !important;
    padding-bottom: 0 !important;   
}

.css-1fdsijx-ValueContainer {
    padding: 0;
}

.css-1xrtqj1-control {
    min-height: 38px !important;
    border:1px solid #7878;
    border-radius: 5px !important;
}
.number-input{
    width: 100px;
    padding: 3px 10px;
    box-sizing: border-box;
    border: 1px solid #787878;
}

.sticky-thead{
    position: sticky;
    top: 0;
}

.submit-button {
    padding: 10px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    width: 100%;
}



.height-adjustment{
    overflow-x: auto;
    height: calc(100vh - 375px);
}

@media screen and (max-width: 992px) {
    .height-adjustment{
        height: calc(100vh - 416px);
    }
}
@media screen and (max-width: 869px) {
    .height-adjustment{
        height: calc(100vh - 490px);
    }
}

@media screen and (max-width: 768px) {
    .filter-adjustment{
        height: 300px;
        overflow-y: auto;
    }
}



@media screen and (max-width: 750px) {
    #searchbar{
        display: flex;
        flex-direction: column;
    }
    #btns3{
     margin-top: 10px;
    }    
}

@media screen and (max-width: 490px) {
    #main-card{
        width: 82vw;
    }  
}

@media screen and (max-width:333px){
    #btns-line{
        display: flex;
        /* flex-wrap: wrap; */
    }
}