#parentM li a:hover {
  background-color: blue;
}

.nav-item{
  white-space: nowrap;
}

#sidebar{
  width:200px;
  z-index: 3;
  transition: 1s all;
}

/* Add this CSS to your sidebarStyles.css */

#sidebar.collapsed {
  width: 60px; /* Adjust this value to your preference */
  transition: 1s all;
}

#sidebar.collapsed .nav-link span {
  display: none;
}


