.container1 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image:linear-gradient(#827cf4,#090979 ,#00d4ff) ;
}
.wrapper {
  position: relative;
  width: 420px;
  border: 2px solid #3155a7;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color: black;
  border-radius: 10px;
  padding: 30px 40px;
  background-color: #fff;
}

.changePasswordWrapper{
  position: relative;
  color: black;
}

.wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  z-index: -1;
  border-radius: 10px;
}

.changePasswordWrapper::before{
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  z-index: -1;
  border-radius: 10px;
}
.wrapper h1 {
  font-size: 36px;
  text-align: center;
  color: #3155a7;
}

.changePasswordWrapper h1 {
  font-size: 36px;
  text-align: center;
  color: #3155a7;
}

.wrapper .input-box {
  position: relative;
  width: 100%;
  height: 40px;
  margin: 30px 0;
}

.changePasswordWrapper .input-box{
  position: relative;
  width: 100%;
  height: 40px;
  margin: 30px 0;
}

.wrapper .input-box-change {
  position: relative;
  width: 100%;
  height: 40px;
  margin: 45px 0;
}

.changePasswordWrapper .input-box-change{
  position: relative;
  width: 100%;
  height: 40px;
  margin: 45px 0;
}


.input-box input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 2px solid #3155a7;
  outline: none;
  border-radius: 40px;
  font-size: 16px;
  color: black;
  padding: 20px 45px 20px 20px;
}

.input-box-change input{
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 2px solid #3155a7;
  outline: none;
  border-radius: 40px;
  font-size: 16px;
  color: black;
  padding: 20px 45px 20px 20px;
}

.input-box input::placeholder {
  color: black;
}

.input-box-change input::placeholder {
  color: black;
}


.input-box .icon {
  position: absolute;
  right: 20px;
  top: 15px;
  font-size: 16px;
}

.input-box-change .icon {
  position: absolute;
  right: 20px;
  top: 39px;
  font-size: 16px;
}


.wrapper .remember-forgot {
  display: flex;
  justify-content: space-between;
  font-size: 14.5px;
  margin: -15px 0 15px;
}

.changePasswordWrapper .remember-forgot{
  display: flex;
  justify-content: space-between;
  font-size: 14.5px;
  margin: -15px 0 15px;
}

.remember-forgot label input {
  accent-color: #fff;
  margin-right: 4px;
}
.remember-forgot a {
  color: black;
  text-decoration: none;
}
.remember-forgot a:hover {
  text-decoration: underline;
}
.wrapper button {
  width: 100%;
  height: 45px;
  background-color: #3155a7;
  border: none;
  outline: none;
  border-radius: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 16px;
  color: white;
  font-weight: 700;
}

.changePasswordWrapper button{
  width: 100%;
  height: 45px;
  background-color: #3155a7;
  border: none;
  outline: none;
  border-radius: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 16px;
  color: white;
  font-weight: 700;
  
}

.wrapper .register-link {
  font-size: 14.5px;
  text-align: center;
  margin: 20px 0 15px;
}
.register-link p a {
  color: #3155a7;
  text-decoration: none;
  font-weight: 600;
}
.register-link p a:hover {
  text-decoration: underline;
}
