/* logout.css */
@media screen and (max-width: 870px) {
    .icon-logout {
      display: none;
    }
    .icon-logout i{
        position: absolute;
        margin-left: -13px;
        text-decoration: none !important;
    }
  }
  @media screen and (min-width:871px) {
    .icon-logout {
      display: none;
    } 
  }